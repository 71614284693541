<template>
  <div class="setPasswordContainer">
    <img
      :src="mainLogo"
      class="setPasswordImage" >
    <div class="setPasswordTitle">{{ companyName }} iQuote Approve Access Request</div>
    <hr >
    <Spinner v-show="loading" />
    <div
      v-show="!loading"
    >
      <div
        v-show="!showAcceptReject && tokenType==''"
        class="setPasswordText">
        The token provided in the link is not valid. Please use the link provided in the email sent to the account email address.
      </div>
      <div
        v-show="rejectedUser"
        class="setPasswordText">
        The user, <b>{{ tokenUsername }}</b> with email address <b>{{ tokenEmailAddress }}</b>, has either been rejected or removed. Please ask the user to re-submit their request if necessary.
      </div>
      <div
        v-show="requestUserCompleted"
        class="setPasswordText">
        The user, <b>{{ tokenUsername }}</b> with email address <b>{{ tokenEmailAddress }}</b>, has been approved. Please check the User Management screen in the portal to confirm.
      </div>
      <div
        v-show="tokenExpired"
        class="setPasswordText">
        The token provided for <b>{{ tokenUsername }}</b> has expired. Please inform the requesting user that they will need to reapply for access.
      </div>
      <div
        v-show="showAcceptReject && requestUserValid"
        class="setPasswordArea">
        <div class="setPasswordHeading">Approve Access Request</div>
        <div>You are viewing this screen because the user <b>{{ tokenUsername }}</b>, with email address <b>{{ tokenEmailAddress }}</b>, has made a request has requested to access to the company <b>{{ tokenCompanyName }}</b> in iQuote.</div>
        <div
          v-show="showCompanySelect"
          class="companyHolder"
        >
          <p>The user has requested access to the company <b>{{ tokenCompanyName }}</b> which was not resolved. Please select the company to set them up with from the list below:</p>
          <PortalDropdown
            v-if="companies.length > 1"
            id="cboCompanySelect"
            :options="companyList"
            :disabled="false"
            :max-item="5000"
            :attach-blur-event="false"
            dropdown-input-class="toolbarSearchInput"
            class="companySelect"
            placeholder="Select Company"
            @selected="changeCompany"
          />
        </div>
        <div>Please confirm that you approve or reject the request for access below.</div>
        <br><br>
        <button
          id="rejectRequest"
          title="Reject the access request for the user"
          class="rejectAccessButton"
          @click="rejectRequest"
        >
          Reject Request
        </button>
        <button
          id="approveAccess"
          title="Approve access for the user"
          class="approveAccessButton"
          @click="approveAccess"
        >
          Approve Access
        </button>
        <br><br>
        <div
          v-show="errorText != ''"
          class="setAccessError"
        >
          {{ errorText }}
        </div>
        <div
          v-show="errorText == ''"
          class="setAccessError"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../components/common/spinner'
import PortalDropdown from '../components/common/portalDropdown'
import { usersMixin } from '../mixins/users'
import { permissionsMixin } from '../mixins/permissions'
import { adminMixin } from '../mixins/adminMixin'
import { setPasswordMixin } from '../mixins/setPassword'
import { DataEventBus } from '../events/dataEvents'
export default {
  metaInfo: {
    meta: [
      { name: 'description', content: `iQuote Approve Access Request` }
    ],
    title: 'iQuote - Access Request'
  },
  components: {
    Spinner,
    PortalDropdown
  },
  mixins: [
    usersMixin,
    adminMixin,
    setPasswordMixin,
    permissionsMixin
  ],
  data () {
    return {
      loading: true,
      showAcceptReject: false,
      tokenExpired: false,
      requestUserValid: false,
      requestUserCompleted: false,
      rejectedUser: false,
      showCompanySelect: false,
      companyList: [],
      tokenType: '',
      tokenEmailAddress: '',
      tokenFirstName: '',
      tokenLastName: '',
      tokenCompanyId: '',
      tokenCompanyName: '',
      errorText: '',
      selectedCompanyId: 0
    }
  },
  computed: {
    mainLogo () {
      if (this.$store.getters.channelCompany.imageRoot === undefined) {
        return '/logo.png'
      } else {
        return `/${this.$store.getters.channelCompany.imageRoot}/${this.$store.getters.siteLogo}`
      }
    },
    companyName () {
      return this.$store.getters.channelCompany.name
    },
    linkUrl () {
      return window.location.origin + '/'
    },
    companies () {
      return this.$store.getters.companies
    },
    currentCompany () {
      return this.$store.getters.currentCompany
    },
    tokenUsername () {
      return this.tokenFirstName + ' ' + this.tokenLastName
    },
    token () {
      if (this.$route.query.token) {
        return this.$route.query.token
      } else {
        return ''
      }
    }
  },
  mounted () {
    DataEventBus.$on('refresh-data', () => {
      this.decodeToken()
    })
    this.decodeToken()
  },
  methods: {
    decodeToken: async function () {
      this.loading = true
      this.showAcceptReject = false
      this.tokenExpired = false
      this.requestUserValid = false
      this.requestUserCompleted = false
      this.rejectedUser = false
      if (this.token.length > 0) {
        var rtn = await this.decryptEmailToken(this.token)
        if (rtn.indexOf('|') > 0) {
          var vals = rtn.split('|')
          if (vals.length === 7) {
            this.tokenEmailAddress = vals[0]
            this.tokenFirstName = vals[1]
            this.tokenLastName = vals[2]
            this.tokenCompanyId = vals[3]
            this.tokenType = vals[4]
            var expires = vals[5]
            this.tokenCompanyName = vals[6]
            if (new Date(expires) < Date.now()) {
              this.tokenExpired = true
            } else {
              // Check that the user is in the requesting access table. If not, then they have been completed.
              var usr = await this.checkRequestAccessUser(this.tokenEmailAddress)
              if (usr.emailAddress === this.tokenEmailAddress) {
                this.requestUserValid = true
              } else {
                this.requestUserCompleted = true
              }
              // Retrieve the corresponding account from the users table.
              var user = await this.getUser(this.tokenEmailAddress)
              // Check that the company identifier is set. If not, show the companies list.
              if (this.tokenCompanyId === '0') {
                // If we have no valid user, then the request has been rejected.
                if (user.emailAddress === null && !this.requestUserValid) {
                  this.rejectedUser = true
                  this.requestUserCompleted = false
                // Otherwise, we'll show the company list with the accept/reject buttons.
                } else {
                  this.setCompanyList()
                  this.showAcceptReject = true
                  this.showCompanySelect = true
                }
              } else {
                if (user.emailAddress !== this.tokenEmailAddress) {
                  // If they are not in the users table but are a requesting user, then show the accept/reject
                  if (this.requestUserValid) {
                    this.showAcceptReject = true
                  } else {
                    // If not and it's not in the request table, then it has been rejected.
                    this.rejectedUser = true
                    this.requestUserValid = false
                    this.requestUserCompleted = false
                  }
                } else {
                  // If so, they have already been approved.
                  this.requestUserCompleted = true
                  this.requestUserValid = true
                }
              }
            }
          }
        }
      }
      this.loading = false
    },
    approveAccess: async function () {
      var payload = '{"FirstName":"' + this.tokenFirstName + '", ' +
        '"LastName":"' + this.tokenLastName + '", ' +
        '"EmailAddress":"' + this.tokenEmailAddress + '", ' +
        '"CompanyId":' + this.tokenCompanyId + ', ' +
        '"Url":"' + window.location.origin + '/' + '", ' +
        '"Approved":true}'
      if (this.tokenCompanyId === '0') {
        this.$store.commit('showMessage', { content: 'Please select a company from the list before proceeding.', color: 'red', timeout: 3000 })
      } else {
        this.$store.commit('showMessage', { content: 'Processing, please wait...', color: 'blue', timeout: 3000 })
        var rtn = await this.approveAccessRequest(payload)
        if (rtn.length === 0) {
          this.$store.commit('showMessage', { content: 'The access request has been completed.', color: 'green', timeout: 3000 })
          this.decodeToken()
          await this.usersGet()
          this.requestUserCompleted = true
        } else {
          this.$store.commit('showMessage', { content: 'An error occurred processing the request:' + rtn, color: 'red', timeout: 5000 })
        }
      }
    },
    rejectRequest: async function () {
      var rtn = await this.deleteRequestAccessUser(this.tokenEmailAddress)
      if (rtn.length === 0) {
        this.$store.commit('showMessage', { content: 'The access request was rejected successfully.', color: 'green', timeout: 3000 })
        this.decodeToken()
      } else {
        this.$store.commit('showMessage', { content: 'An error occurred rejecting the access request:' + rtn, color: 'red', timeout: 5000 })
      }
    },
    setCompanyList () {
      this.companyList = []
      this.companyList.push({ id: 0, name: 'Please Select' })
      for (let i = 0; i < this.companies.length; i++) {
        this.companyList.push({ id: i + 1, name: this.companies[i].name })
        // if (this.companies[i].id === this.currentCompany.id) {
        //   this.selectedCompanyId = this.companies[i].id
        //   console.log('Setting this.tokenCompanyId: ' + this.tokenCompanyId + ' to: ' + this.selectedCompanyId)
        //   this.tokenCompanyId = this.selectedCompanyId
        // }
      }
    },
    changeCompany ({ name: selectedName }) {
      if (selectedName === 'Please Select') {
        this.selectedCompanyId = 0
        this.tokenCompanyId = '0'
      } else {
        for (let i = 0; i < this.companies.length; i++) {
          if (this.companies[i].name === selectedName) {
            this.selectedCompanyId = this.companies[i].id
            this.tokenCompanyId = this.selectedCompanyId
          }
        }
      }
    }
  }
}
</script>
<style scoped>
  .approveAccessButton {background-color:#a7ffab; font-weight: bold;border: 1px solid #62ff6a; padding: 5px 10px; border-radius: 5px;cursor: pointer;float:right;}
  .rejectAccessButton {background-color:#ff98a5; font-weight: bold;border: 1px solid #ff3d57; padding: 5px 10px; border-radius: 5px;cursor: pointer;float:left;}
  .approveAccessButton:hover {background-color:#62ff6a;color: white;}
  .rejectAccessButton:hover {background-color:#ff3d57;color: white;}
  .setPasswordContainer {width: 100%;padding: 20px 40px;}
  .setPasswordImage {margin: 20px auto; display: inline-block;}
  .setPasswordTitle {font-size: 2em; font-weight: bold; display: inline-block; vertical-align: top; margin: 30px;}
  .setPasswordText {margin: 20px 0 20px 20px; width: 100%;}
  .setPasswordArea {width: 60%;padding: 40px;border:black 1px solid; margin: 30px auto; min-width:300px;text-align:center;}
  .setPasswordHeading {font-size: 1.6em; font-weight: bold; margin-bottom: 10px;}
  .passwordText {width: 200px; display: inline-block;}
  .changePasswordButton {float:right;}
  .setAccessError {color: darkred;}
  .companyHolder {padding:10px 0;}
  .companySelect {border: solid 1px #aaa; border-radius: 5px;margin-bottom: 10px;text-align: left;}
</style>
